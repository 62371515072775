// src/Login.js
import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';
import config from '../../config'
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [UserName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(''); // State to track error messages
    const navigate = useNavigate();

    const validateForm = () => {
        // Check if fields are not empty
        if (!UserName || !password) {
            setError("Username and password are required.");
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear any previous errors

        if (!validateForm()) {
            return; // If validation fails, do not proceed
        }

        try {
            const response = await axios.post(`${config.API_BASE_URL}/login`, { UserName, password });
            console.log(response.data);
            localStorage.clear();
            sessionStorage.clear();
            navigate('/dashboards');
        } catch (error) {
            console.error('There was an error!', error);
            // Show error message if login fails
            if (error.response && error.response.status === 401) {
                setError('Invalid username or password.');
            } else {
                setError('An error occurred during login. Please try again.');
            }
        }
    };

    return (
        <div className="login-container">
            <div className="form-container">
                <p className="title">Login</p>
                <form className="form" onSubmit={handleSubmit}>

                    <div className="input-group">
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            name="username"
                            id="username"
                            placeholder=""
                            value={UserName}
                            onChange={(e) => setUserName(e.target.value)}
                        />
                    </div>
                    <div className="input-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            placeholder=""
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <div className="forgot">
                            <a href="#" rel="noopener noreferrer">
                                Forgot Password?
                            </a>
                        </div>
                    </div>
                    <button type="submit" className="loginButton">
                        Login
                    </button>
                    {/* Display error message */}
                    {error && <p className="error-message">{error}</p>}

                </form>
            </div>
        </div>
    );
};

export default Login;
