import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});

  const updateFilters = (type, value) => {
    setSelectedFilters((prev) => ({ ...prev, [type]: value }));
  };
  const resetFilters = (pageSpecificFilters) => {
    // Clear only filters specific to the current page
    setSelectedFilters((prev) => {
      const updatedFilters = { ...prev };
      Object.keys(filters[pageSpecificFilters] || {}).forEach((key) => {
        updatedFilters[key] = ''; // Reset each filter to its default state
      });
      return updatedFilters;
    });
  };
  return (
    <FilterContext.Provider value={{ filters, setFilters, selectedFilters, updateFilters, resetFilters }}>
      {children}
    </FilterContext.Provider>
  );
};
