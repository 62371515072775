import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './TabComponent.css'; // Optional for custom styling
import { NavLink } from 'react-router-dom';

const TabComponent = () => {
  const [activeTab, setActiveTab] = useState(1); // Default to the first tab

  return (
    <div className="tab-wrapper">
      <div className="tab-box">
        <div className="tab-navigation">
          {/* Tab 1 */}
          <div
            className={`tab-item ${activeTab === 1 ? 'active' : ''}`}
            onClick={() => setActiveTab(1)}
          >
            <nav className="nav-tabs">
              <ul>
                <li>
                  <NavLink to="/overallshares"
                    className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}>
                    Transactions
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>

          {/* Tab 2 */}
          <div
            className={`tab-item ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => setActiveTab(2)}
          >
            <nav className="nav-tabs">
              <ul>
                <li>
                  <NavLink
                    className={({ isActive }) => (isActive ? 'filter-button active' : 'filter-button')}>
                    Respondent
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        {/* Content for the selected tab */}
        <div className="tab-content">
          {activeTab === 1 ? (
            <div>Content available for Overall Shares</div>
          ) : (
            <div>No Content Available</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
