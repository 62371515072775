import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FilterTabs.css'; // Import the CSS file
import config from '../../config';
import classNames from 'classnames';

function FilterTabs({ onFilterUpdate }) {
  // State for filters fetched from the server
  const [filters, setFilters] = useState({});
  // State to track the active filter type (e.g., 'user_gender')
  const [activeFilter, setActiveFilter] = useState(null);
  // State to track the selected filter values (can be multiple for each filter type)
  const [selectedFilters, setSelectedFilters] = useState({});

  // Fetch the filter data when the component mounts
  useEffect(() => {
    axios
      .get(`${config.API_BASE_URL}/api/tabs`)
      .then(response => setFilters(response.data))
      .catch(error => console.error("There was an error fetching the filters:", error));
  }, []);

  // Handle tab click to set the active filter and update selected values
  const handleTabClick = (filterType, filterValue) => {
    // Set the active filter type
    setActiveFilter(filterType);

    // Update selectedFilters state to allow multiple selections for a filter type
    setSelectedFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };

      // Ensure the selected filter type exists in the state
      if (!updatedFilters[filterType]) {
        updatedFilters[filterType] = [];
      }

      // Normalize the filterValue to handle case sensitivity
      const normalizedValue = filterValue.toLowerCase();

      // If the value is already selected, remove it, otherwise add it
      if (updatedFilters[filterType].includes(normalizedValue)) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          value => value !== normalizedValue
        );
      } else {
        updatedFilters[filterType].push(normalizedValue);
      }

      // Log the selected filter values after updating
      // console.log("Selected Filter Type:", filterType);
      // console.log("Selected Filter Values:", updatedFilters[filterType]);

      // Dynamically filter the filters variable based on the active filter type
      if (filters[filterType]) {
        const filteredValues = filters[filterType]

        // Log each value of the filtered results
        filteredValues.forEach(item => {
          console.log(`Filtered ${filterType} Value:`, item.value);

          // Pass filtered values back to parent (PaymentTable)
          onFilterUpdate(filterType, filteredValues);
        });
      }

      return updatedFilters;
    });
  };

  return (
    <div className="filter-tabs-container">
      {Object.keys(filters).map((filterType) => (
        <div key={filterType}>
          {/* Create a Set to store unique display names for each filter type */}
          {[...new Set(filters[filterType].map((filter) => filter.displayName))].map(
            (uniqueDisplayName) => {
              // Find the corresponding filter value for the displayName
              const filter = filters[filterType].find(
                (filter) => filter.displayName === uniqueDisplayName
              );
              return (

                <button
                  key={filter.value}
                  className={classNames('filter-button', {
                    active:
                      activeFilter === filterType &&
                      selectedFilters[filterType]?.includes(filter.value.toLowerCase()),
                  })}
                  onClick={() => handleTabClick(filterType, filter.value)}
                  onTouchStart={() => handleTabClick(filterType, filter.value)}
                >
                  {uniqueDisplayName}
                </button>
              );
            }
          )}
        </div>
      ))}
    </div>
  );
}

export default FilterTabs;