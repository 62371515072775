import React, { useEffect, useState, useContext } from 'react';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from '../../components/pages/FilterContext'; // Import FilterContext
import Filters from './Filters';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

const SecondView = () => {
    const { selectedFilters } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]); // Holds table data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [dataSummary, setDataSummary] = useState(null); // Data summary state
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('All'); // Default to "All"
    const [selectedPaymentMethodBrandSharesData, setSelectedPaymentMethodBrandSharesData] = useState('All'); // Default to "All"
    const [brandSharesData, setBrandSharesData] = useState([]); // Holds table data

    const navigate = useNavigate(); // Hook for navigation

    // Fetch table data based on selected filters
    useEffect(() => {
        setLoading(true);
        axios
            .post(`${config.API_BASE_URL}/api/second-view`, selectedFilters)
            .then((response) => {
                setTableData(response.data.base_results || []);
                setBrandSharesData(response.data.brand_shares || []);;
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching table data:', err);
                setError('Failed to load data.');
                setLoading(false);
            });
    }, [selectedFilters]);

    // Fetch data summary for total transactions
    useEffect(() => {
        fetch(`${config.API_BASE_URL}/api/data-summary`)
            .then((response) => response.json())
            .then((data) => {
                setDataSummary(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data summary:', error);
                setError('Failed to fetch data summary');
                setLoading(false);
            });
    }, []);

    // Filter tableData based on the selected payment method
    const filteredTableData = selectedPaymentMethod === 'All'
        ? tableData
        : tableData.filter((item) => item.payment_method.includes(selectedPaymentMethod));
    // Extract unique payment methods and years
    const paymentMethods = [...new Set(filteredTableData.map(item => item.payment_method))];
    const years = [...new Set(filteredTableData.map(item => item.trans_year))];

    // Create a structure for the table data
    const structuredTableData = years.map(year => {
        return {
            year,
            data: paymentMethods.map(paymentMethod => {
                const entry = filteredTableData.find(
                    item => item.trans_year === year && item.payment_method === paymentMethod
                );
                return entry ? entry.percentage : 0; // default to 0 if no entry found
            })
        };
    });

    const filteredTableDataForBrandShares = selectedPaymentMethodBrandSharesData === 'All'
        ? brandSharesData
        : brandSharesData.filter((item) => item.payment_method.includes(selectedPaymentMethodBrandSharesData));

    // Debugging logs to ensure filtering works as expected
    console.log('Filtered Table Data for Brand Shares:', filteredTableDataForBrandShares);

    // Extract unique payment methods and years
    const paymentMethodsForBrandShares = [...new Set(filteredTableDataForBrandShares.map(item => item.payment_method))];
    const yearsForBrandShares = [...new Set(filteredTableDataForBrandShares.map(item => item.trans_year))];

    // Debugging logs to check the extracted methods and years
    console.log('Payment Methods for Brand Shares:', paymentMethodsForBrandShares);
    console.log('Years for Brand Shares:', yearsForBrandShares);

    // Create a structure for the table data
    const structuredTableDataForBrandShares = yearsForBrandShares.map(year => {
        return {
            year,
            data: paymentMethodsForBrandShares.map(paymentMethod => {
                const entry = filteredTableDataForBrandShares.find(
                    item => item.trans_year === year && item.payment_method === paymentMethod
                );
                return entry ? entry.percentage : 0; // default to 0 if no entry found
            })
        };
    });

    // Debugging logs to check the structured data
    console.log('Structured Table Data for Brand Shares:', structuredTableDataForBrandShares);

    const goBack = () => {
        navigate('/dashboards'); // Go back to the previous page/tab
      };
    

    return (
        <div className="container">
            <Filters pageSpecificFilters="payment" />
        {/* Back Button */}
            {/* Dropdown for selecting Payment Method */}
            <div className="table-section">
        <button onClick={goBack} className="back-button">
          &#8592; Back
        </button>
                <div className="filter-tabs-container">
                    <button className='filter-button' onClick={() => setSelectedPaymentMethod('All')}>All</button>
                    <button className='filter-button' onClick={() => setSelectedPaymentMethod('Debit')}>Debit</button>
                    <button className='filter-button' onClick={() => setSelectedPaymentMethod('Credit')}>Credit</button>
                    <button className='filter-button' onClick={() => setSelectedPaymentMethodBrandSharesData('Interac Debit')}>Interac Debit</button>
                    <button className='filter-button' onClick={() => setSelectedPaymentMethodBrandSharesData('Visa Debit')}>Visa Debit</button>
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="total-transac">
                                    <u>Total Transactions</u>
                                    <br />
                                    <label className="label">
                                        <i>
                                            <strong>Base:</strong> {dataSummary?.trans}
                                        </i>
                                    </label>
                                </th>
                                <th className="share-column">Share of Transactions</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                {loading ? (
                    <p className="loading">Loading data...</p>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : filteredTableData.length === 0 ? (
                    <p className="no-data">No data available for the selected filters.</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Payment Methods</th>
                                {years.map(year => (
                                    <th key={year}>{year}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paymentMethods.map(paymentMethod => (
                                <tr key={paymentMethod}>
                                    <td>{paymentMethod.replace('-Total', '')}</td>
                                    {(structuredTableData.map(row => (
                                        <td key={row.year}>
                                            {row.data[paymentMethods.indexOf(paymentMethod)] !== 0
                                                ? `${row.data[paymentMethods.indexOf(paymentMethod)].toFixed(1)}%`
                                                : 'N/A'}
                                        </td>
                                    )))}
                                </tr>
                            ))}

                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default SecondView;
