import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from '../../components/pages/FilterContext';
import Filters from './Filters';
import FilterTabs from './FilterTabs';
import config from '../../config';

const PaymentTable = () => {
  const { selectedFilters } = useContext(FilterContext);
  const [tableData, setTableData] = useState([]); // Holds table data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [uniqueYears, setUniqueYears] = useState([]); // Dynamic headers
  const [data, setData] = useState(null); // Data summary state
  const [filterTabs, setFilterTabs] = useState({});

  const navigate = useNavigate(); // Hook for navigation

  const handleFilterUpdate = (filterType, filteredValues) => {
    setFilterTabs((prevTabs) => ({
      ...prevTabs,
      [filterType]: filteredValues.map(item => item.value), // Only include values
    }));
  };
  const filterTabKey = Object.keys(filterTabs)[0];

  useEffect(() => {
    setLoading(true);
    setUniqueYears([]); // Reset uniqueYears when filterTabs changes
    axios
      .post(`${config.API_BASE_URL}/api/payment-data`, {
        selectedFilters,
        filterTabs,
      })
      .then((response) => {
        const data = Array.isArray(response.data) ? response.data : [];
        setTableData(data);
        console.log("response.data", response.data);
        console.log("filterTabs", filterTabs);
        let dynamicPattern = null;

        if (Object.keys(filterTabs).length === 0) {
          // If filterTabs is empty, use default year patterns
          const yearPatternWithSuffix = /_(\d{4}_\d)$/; // Matches years with a suffix (e.g., perc_2023_1)
          const yearPatternWithoutSuffix = /_(\d{4})$/; // Matches years at the end of column keys (e.g., perc_2023)

          const hasYearWithSuffix = data.some((row) =>
            Object.keys(row).some((key) => yearPatternWithSuffix.test(key))
          );

          if (hasYearWithSuffix) {
            const years = Array.from(
              new Set(
                data.flatMap((row) =>
                  Object.keys(row)
                    .filter((key) => key.startsWith('perc_') || key.startsWith('shares_'))
                    .map((key) => key.match(yearPatternWithSuffix)?.[1])
                    .filter(Boolean)
                )
              )
            );
            setUniqueYears(years);
            console.log('Extracted years with suffix:', years);
          } else {
            const years = Array.from(
              new Set(
                data.flatMap((row) =>
                  Object.keys(row)
                    .filter((key) => key.startsWith('perc_') || key.startsWith('shares_'))
                    .map((key) => key.match(yearPatternWithoutSuffix)?.[1])
                    .filter(Boolean)
                )
              )
            );
            setUniqueYears(years);
            console.log('Extracted years without suffix:', years);
          }
        } else {
          // If filterTabs has keys, dynamically generate the pattern
          const dynamicFilterKey = Object.keys(filterTabs)[0] || 'user_gender'; // Default to 'user_gender' if not specified
          dynamicPattern = new RegExp(
            `${dynamicFilterKey}_(\\w+)_(\\d{4})_(\\d)` // Matches user_gender_male_2017_0
          );

          const extractedYears = Array.from(
            new Set(
              data.flatMap((row) =>
                Object.keys(row)
                  .filter((key) => key.startsWith('perc_') || key.startsWith('shares_'))
                  .map((key) => {
                    const match = key.match(dynamicPattern);
                    return match ? `${match[1]}_${match[2]}_${match[3]}` : null; // Format as `male_2016`
                  })
                  .filter(Boolean)
              )
            )
          );
          setUniqueYears(extractedYears);
          console.log('Extracted dynamic years:', extractedYears);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching table data:', err);
        setError('Failed to load data.');
        setLoading(false);
      });
  }, [selectedFilters, filterTabs]);

  useEffect(() => {
    fetch(`${config.API_BASE_URL}/api/data-summary`) // Replace with the correct backend URL
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data summary:', error);
        setError('Failed to fetch data summary');
        setLoading(false);
      });
  }, []);

  const goBack = () => {
    navigate('/dashboards'); // Go back to the previous page/tab
  };

  // Preprocess uniqueYears to sort in ascending order
  const processedYears = uniqueYears
    .map((year) => ({
      original: year,
      year: parseInt(year.match(/\d{4}/)[0]), // Extract the year (e.g., 2016 from "Female_2016_0")
    }))
    .sort((a, b) => b.year - a.year) // Sort by year in ascending order
    .map((item) => item.original); // Map back to the original strings


  return (
    <div className="container">
      <Filters pageSpecificFilters="payment" />
      <div className="table-section">
        {/* Back Button */}
        <button onClick={goBack} className="back-button">
          &#8592; Back
        </button>

        <FilterTabs onFilterUpdate={handleFilterUpdate} />

        <div>
          <table>
            <thead>
              <tr>
                <th className="total-transac">
                  <u>Total Transactions</u>
                  <br />
                  <label className="label">
                    <i>
                      <strong>Base:</strong> {data?.trans}
                    </i>
                  </label>
                </th>
                <th className="share-column">Share of Transactions</th>
              </tr>
            </thead>
          </table>
        </div>

        {loading ? (
          <p className="loading">Loading data...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : tableData.length === 0 ? (
          <p className="no-data">No data available for the selected filters.</p>
        ) : (
          <div className='tablesview'>
          <table>
            {/* {uniqueYears} */}
            {/* {JSON.stringify(tableData)} */}
            <thead>
              <tr>
                <th>Payment Methods</th>
                {processedYears.map((year) => (
                  <th key={`${year}`}>
                    {year
                      ? year
                        .replace('_', '.') // Replace underscore with a dot
                        .replace(/\.0$/, '') // Remove trailing ".0" if present
                        .replace('.', '_')
                        .replace(/\_0$/, '') // Remove trailing ".0" if present
                      : ''}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.payment}</td>
                  {processedYears.map((year) => (
                    <td key={`perc_${year}`}>
                      {row[`perc_${year}`] !== undefined
                        ? Number(row[`perc_${year}`]).toFixed(1)
                        : row[`perc_${filterTabKey}_${year}`] !== undefined
                          ? Number(row[`perc_${filterTabKey}_${year}`]).toFixed(1)
                          : 'N/A'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentTable;
