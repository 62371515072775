import React, { useEffect, useState, useContext } from 'react';
import './OverallShares.css';
import axios from 'axios';
import { FilterContext } from './FilterContext'; // Import FilterContext
import Filters from './Filters';
import config from '../../config';
import { useNavigate } from 'react-router-dom';

const ThirdView = () => {
    const { selectedFilters } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]); // Holds table data
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state
    const [dataSummary, setDataSummary] = useState(null); // Data summary state
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('All'); // Default to "All"

    const navigate = useNavigate(); // Hook for navigation

    // Fetch table data based on selected filters
    useEffect(() => {
        setLoading(true);
        axios
            .post(`${config.API_BASE_URL}/api/second-view`, selectedFilters)
            .then((response) => {
                setTableData(response.data.brand_shares || []);;
                setLoading(false);
            })
            .catch((err) => {
                console.error('Error fetching table data:', err);
                setError('Failed to load data.');
                setLoading(false);
            });
    }, [selectedFilters]);

    // Fetch data summary for total transactions
    useEffect(() => {
        fetch(`${config.API_BASE_URL}/api/data-summary`)
            .then((response) => response.json())
            .then((data) => {
                setDataSummary(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data summary:', error);
                setError('Failed to fetch data summary');
                setLoading(false);
            });
    }, []);

    // Filter tableData based on the selected payment method
    const filteredTableData = selectedPaymentMethod === 'All'
        ? tableData
        : tableData.filter((item) => item.payment_method.includes(selectedPaymentMethod));

    // Extract unique payment methods and years
    const paymentMethods = [...new Set(filteredTableData.map(item => item.payment_method))];
    const years = [...new Set(filteredTableData.map(item => item.trans_year))];

    // Create a structure for the table data
    const structuredTableData = years.map(year => {
        return {
            year,
            data: paymentMethods.map(paymentMethod => {
                const entry = filteredTableData.find(
                    item => item.trans_year === year && item.payment_method === paymentMethod
                );
                return entry ? entry.percentage : 0; // default to 0 if no entry found
            })
        };
    });
    const goBack = () => {
      navigate('/dashboards'); // Go back to the previous page/tab
    };
  

    return (
        <div className="container">
            <Filters pageSpecificFilters="payment" />
            {/* Dropdown for selecting Payment Method */}
            <div className="table-section">
        {/* Back Button */}
        <button onClick={goBack} className="back-button">
          &#8592; Back
        </button>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th className="total-transac">
                                    <u>Total Transactions</u>
                                    <br />
                                    <label className="label">
                                        <i>
                                            <strong>Base:</strong> {dataSummary?.trans}
                                        </i>
                                    </label>
                                </th>
                                <th className="share-column">Share of Transactions</th>
                            </tr>
                        </thead>
                    </table>
                </div>

                {loading ? (
                    <p className="loading">Loading data...</p>
                ) : error ? (
                    <p className="error">{error}</p>
                ) : filteredTableData.length === 0 ? (
                    <p className="no-data">No data available for the selected filters.</p>
                ) : (
                    <div className='tablesview'>
                    <table>
                        <thead>
                            <tr>
                                <th>Payment Methods</th>
                                {years.map(year => (
                                    <th key={year}>{year}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {paymentMethods.map(paymentMethod => (
                                <tr key={paymentMethod}>
                                    <td>{paymentMethod.replace('-Total', '')}</td>
                                    {structuredTableData.map(row => (
                                        <td key={row.year}>
                                            {row.data[paymentMethods.indexOf(paymentMethod)] !== 0
                                                ? `${row.data[paymentMethods.indexOf(paymentMethod)].toFixed(1)}%`
                                                : 'N/A'}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ThirdView;
