import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../config'
import './SpssPage.css'
import { useNavigate } from 'react-router-dom';

function SpssPage() {
  const [variables, setVariables] = useState([]);
  const [data, setData] = useState([]);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Hook for navigation

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) return alert('Please select an SPSS file to upload');

    const formData = new FormData();
    formData.append('spssFile', file);

    try {
      const response = await axios.post(`${config.API_BASE_URL}/upload-spss`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMessage(response.data.message || "File uploaded successfully!");
      setVariables(response.data.variables || []);
      setData(response.data.data || []);
    } catch (error) {
      console.error('Error uploading file:', error.response ? error.response.data : error.message);
      alert('File upload failed. Please check the server logs for details.');
    }
  };

  const goBack = () => {
    navigate('/dashboards'); // Go back to the previous page/tab
  };
  return (
    <div className="upload-container">
    {/* Back Button */}
    <button onClick={goBack} className="back-button">
      &#8592; Back
    </button>
      <h2 className="upload-title">Upload SPSS File</h2>
      <input
        type="file"
        accept=".sav"
        onChange={handleFileChange}
        className="file-input"
      />
      <button onClick={handleFileUpload} className="upload-button">
        Upload
      </button>

      {variables.length > 0 && (
        <div className="variables-container">
          <h3 className="variables-title">Variables:</h3>
          <ul className="variables-list">
            {variables.map((variable, index) => (
              <li key={index} className="variable-item">{variable}</li>
            ))}
          </ul>
        </div>
      )}

      {data.length > 0 && (
        <div className="data-table-container">
          <h3 className="data-table-title">Data Table:</h3>
          <table className="data-table">
            <thead>
              <tr>
                {variables.map((variable, index) => (
                  <th key={index} className="table-header">{variable}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex} className="table-row">
                  {variables.map((variable, colIndex) => (
                    <td key={colIndex} className="table-cell">
                      {row[variable]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SpssPage;
