import React, { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import './OneDriveFiles.css';
import excel from '../../assets/img/excel.png';
import image from '../../assets/img/image.png';
import file from '../../assets/img/fold.png';
import docx from '../../assets/img/word.png';
import pdf from '../../assets/img/pdf.png';
import fold from '../../assets/img/fold.png';
import spss from '../../assets/img/spss.png';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';

const OneDriveFiles = () => {
  const { instance, accounts } = useMsal();
  const [files, setFiles] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const [fileTypeFilter, setFileTypeFilter] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    if (accounts.length > 0) {
      instance.acquireTokenSilent({
        scopes: ["Files.Read"],
        account: accounts[0],
      })
        .then(tokenResponse => {
          const headers = { Authorization: `Bearer ${tokenResponse.accessToken}` };
          axios.get('https://graph.microsoft.com/v1.0/me/drive/root:/DigInsight:/children', { headers })
            .then(response => {
              setFiles(response.data.value);
              setFilteredFiles(response.data.value);
            })
            .catch(err => {
              console.error("Error fetching OneDrive files:", err);
              setError("Failed to fetch files from OneDrive.");
            });
        })
        .catch(err => {
          console.error("Token acquisition failed:", err);
          setError("Failed to acquire token.");
        });
    }
  }, [instance, accounts]);

  const handleFilterChange = (e) => {
    const selectedFileType = e.target.value;
    setFileTypeFilter(selectedFileType);

    if (selectedFileType) {
      const filtered = files.filter(file => file.name.endsWith(selectedFileType));
      setFilteredFiles(filtered);
    } else {
      setFilteredFiles(files);
    }
  };

  const handleDownload = (downloadUrl) => {
    window.open(downloadUrl, '_blank');
  };

  const handleFolderDownload = (folder) => {
    const zip = new JSZip();
    const folderFiles = folder.children || folder.value || [];

    if (folderFiles.length === 0) {
      setError("This folder has no files to download.");
      return;
    }

    folderFiles.forEach(file => {
      const fileName = file.name;
      const fileUrl = file['@microsoft.graph.downloadUrl'];

      axios.get(fileUrl, { responseType: 'arraybuffer' })
        .then((response) => {
          zip.file(fileName, response.data);

          if (folderFiles.indexOf(file) === folderFiles.length - 1) {
            zip.generateAsync({ type: 'blob' })
              .then((content) => {
                saveAs(content, `${folder.name}.zip`);
              });
          }
        })
        .catch((error) => {
          console.error(`Failed to download file ${file.name}:`, error);
        });
    });
  };

  const getFileIcon = (file) => {
    if (!file || !file.name) {
      return <img src="/assets/file-icons/file.png" alt="default file icon" />;
    }

    if (file.folder) {
      return <img src={fold} alt="Folder icon" style={{ width: '24px', height: '24px' }} />;
    }

    const extension = file.name.split('.').pop().toLowerCase();

    switch (extension) {
      case 'pdf':
        return <img src={pdf} alt="PDF file icon" />;
      case 'doc':
      case 'docx':
        return <img src={docx} alt="Word file icon" />;
      case 'xls':
      case 'xlsx':
        return <img src={excel} alt="Excel file icon" style={{ width: '24px', height: '24px' }} />;
      case 'csv':
        return <img src={excel} alt="CSV file icon" style={{ width: '24px', height: '24px' }} />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <img src={image} alt="Image file icon" />;
      case 'sav':
        return <img src={spss} alt="SPSS file icon" style={{ width: '24px', height: '24px' }} />;
      default:
        return <i className="fa fa-file-o" aria-hidden="true"></i>;
    }
  };
  
  const goBack = () => {
    navigate('/dashboards'); // Go back to the previous page/tab
  };

  return (
    <div>
    {/* Back Button */}
    <button onClick={goBack} className="back-button">
      &#8592; Back
    </button>
      {error && <p>{error}</p>}
      <ul className="file-list">
        {filteredFiles.length > 0 ? (
          filteredFiles.map(file => (
            <li key={file.id} className="file-item">
              {/* Removed the link, preventing file access directly via URL */}
              <div>
                {getFileIcon(file)}{file.name}
              </div>
              {file.folder ? (
                <button onClick={() => handleFolderDownload(file)} className="downloadButton">
                  <i className="fa fa-download"></i>
                </button>
              ) : (
                <button onClick={() => handleDownload(file['@microsoft.graph.downloadUrl'])} className="downloadButton">
                  <i className="fa fa-download"></i>
                </button>
              )}
            </li>
          ))
        ) : (
          <p>No files found or you are not logged in.</p>
        )}
      </ul>
    </div>
  );
};

export default OneDriveFiles;
