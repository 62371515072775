import React, { useEffect, useContext } from 'react';
import { FilterContext } from './FilterContext';
import axios from 'axios';
import config from '../../config';

const Filters = ({ pageSpecificFilters }) => {
  const { filters, setFilters, selectedFilters, updateFilters, resetFilters } = useContext(FilterContext);

  useEffect(() => {
    if (!filters[pageSpecificFilters]) {
      axios
        .get(`${config.API_BASE_URL}/api/filters`, {
          params: { filterType: pageSpecificFilters }  // Pass the filter type as a query parameter
        })
        .then((response) =>
          setFilters((prev) => ({ ...prev, [pageSpecificFilters]: response.data }))
        )
        .catch((err) => console.error('Error fetching filters:', err));
    }
  }, [pageSpecificFilters, filters, setFilters]);
  const handleReset = () => {
    resetFilters(pageSpecificFilters); // Reset filters using the context method
  };

  const currentFilters = filters[pageSpecificFilters] || {};

  return (
    <div className="filters">
      <span style={{display:'flex', justifyContent:'space-between'}}>
      <h3>Filters</h3>
      {/* Add Reset Button */}
          <button className='filter-button '>
          Reset Filters
          </button>
          </span>
      {Object.entries(currentFilters).length === 0 ? (
        <p>Loading filters...</p>
      ) : (
        Object.entries(currentFilters).map(([type, values]) => (
          <div key={type} style={{ marginBottom: '20px' }}>
            <label style={{ marginBottom: '5px', display: 'block' }}>
              {type.replace(/_/g, ' ').toUpperCase()}:
            </label>

            <select
              onChange={(e) => updateFilters(type, e.target.value)}
              value={selectedFilters[type] || ''}
            >
              <option value="">All</option>
              {values.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        ))
      )}
    </div>
  );
};

export default Filters;
