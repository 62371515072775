import React from 'react';
import { useMsal } from '@azure/msal-react';

const LoginButton = () => {
  const { instance } = useMsal();

  const handleLogin = () => {
    localStorage.clear();
    sessionStorage.clear();

    instance.loginPopup({
      scopes: ["Files.Read", "Files.ReadWrite"],
    }).catch(e => {
      console.error(e);
    });
  };

  return <button onClick={handleLogin} className='loginButton'>Login to OneDrive</button>;
};

export default LoginButton;
